import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';
import {
  CandidatureTypes,
  CompanyInfo,
  InternshipOpportunityTypes,
  InterviewDetails,
  candidatureInternProfileTypes,
  candidatureInterviewsResponseTypes,
  candidatureOfferResponseTypes,
  CommonResponseType,
  GetReasonsResponseType,
  UpdateReasonParamsType,
  CheckNotInterestedInternResponseType,
} from './Candidature.d';
const notInterestedUrl = '/host-company/browse/not-interested';
const notInterestedReasonsUrl = 'common-services/not-interested/reasons';
const getCandidaturesUrl = '/host-company/candidatures';
const getInternshipPlansUrl = '/host-company/internship-plan';
const getInterviewDetails = 'host-company/dashboard/interview-details';
const setInterviewTimeSlots = 'host-company/dashboard/select-interview-timeslot';
const rescheduleInterview = 'host-company/dashboard/reschedule-interview';
const candidatureByIdUrl = '/host-company/candidature';
const withdrawInterviewRequestUrl = '/host-company/dashboard/withdraw-interview';
const editInterviewRequestUrl = '/host-company/dashboard/update-notes-link';
const getFeedbackQuestionsAndOptionsUrl = '/common-services/feedback-reasons/list';
const submitInterviewFeedbackResponseUrl = '/host-company/interview-feedback/create';
const createOrUpdateOfferUrl = '/host-company/offer/io';
const getAppBatchDetailsUrl = '/host-company/offer/get-application-batch-details';
const submitOfferDetailsUrl = '/host-company/offer/send-intern-offer';
const updateOfferStepTwoUrl = '/host-company/offer/v2/save-offer-supervisor';
const scheduleInterviewUrl = 'host-company/browse-intern/schedule-interview';

export const CandidatureApi = createApi({
  reducerPath: 'candidatureApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'internshipPlans',
    'candidatures',
    'candidatureInternProfile',
    'candidatureInterviews',
    'candidatureOffers',
    'companyMandatoryDetails',
    'candidatureActions',
    'CheckNotInterestedApi',
  ],

  endpoints: (builder) => ({
    getCandidatures: builder.query<CandidatureTypes, Record<string, string>>({
      query: (searchParams) => {
        let url = getCandidaturesUrl;
        if (searchParams && Object.keys(searchParams).length > 0) {
          const params = new URLSearchParams(searchParams);
          url = `${getCandidaturesUrl}?${params.toString()}`;
        }
        return { url, method: 'GET', body: null };
      },
      providesTags: ['candidatures'],
    }),

    getInternshipPlans: builder.query<InternshipOpportunityTypes, Record<string, string>>({
      query: (searchParams) => {
        let url = getInternshipPlansUrl;
        if (searchParams) {
          const params = new URLSearchParams(searchParams);
          url = `${getInternshipPlansUrl}?${params.toString()}`;
        }
        return { url, method: 'GET', body: null };
      },
      providesTags: ['internshipPlans'],
    }),

    getInterviewDetails: builder.query<InterviewDetails, Record<string, number>>({
      query: ({ id }) => {
        const url = getInterviewDetails + `/${id}`;
        return { url, method: 'GET', body: null };
      },
    }),

    setInterviewTimeSlots: builder.mutation({
      query: (body) => {
        const url = setInterviewTimeSlots;
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['candidatures', 'candidatureInterviews', 'candidatureActions'],
    }),

    rescheduleInterview: builder.mutation({
      query: (body) => {
        const url = rescheduleInterview;
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['candidatures', 'candidatureInterviews', 'candidatureActions'],
    }),

    getCandidatureInternProfile: builder.query<candidatureInternProfileTypes, number>({
      query: (candidatureId) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/intern`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['candidatureInternProfile'],
    }),

    getCandidatureInterviews: builder.query<candidatureInterviewsResponseTypes, number>({
      query: (candidatureId) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/interviews`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['candidatureInterviews'],
    }),

    getCandidatureOffers: builder.query<candidatureOfferResponseTypes, number>({
      query: (candidatureId) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/offers`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['candidatureOffers'],
    }),

    withdrawInterviewRequest: builder.mutation({
      query: (body) => ({ url: withdrawInterviewRequestUrl, method: 'POST', body }),
      invalidatesTags: ['candidatureInterviews', 'candidatures', 'candidatureActions'],
    }),

    editInterviewRequest: builder.mutation({
      query: (body) => ({ url: editInterviewRequestUrl, method: 'POST', body }),
      invalidatesTags: ['candidatureInterviews'],
    }),
    submitInterviewFeedback: builder.mutation({
      query: (body) => ({ url: submitInterviewFeedbackResponseUrl, method: 'POST', body }),
      invalidatesTags: ['candidatureInterviews', 'candidatures', 'candidatureActions'],
    }),
    getFeedbackQuestionsAndOptions: builder.query({
      query: () => {
        const url = getFeedbackQuestionsAndOptionsUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    markCandidateAsHired: builder.mutation({
      query: (id) => {
        const url = `/host-company/candidature/${id}/hired`;
        return { url, method: 'PATCH', body: {} };
      },
      invalidatesTags: ['candidatures', 'candidatureOffers', 'candidatureActions'],
    }),
    disqaulifyCandidate: builder.mutation({
      query: ({ id, ...body }) => {
        const url = `/host-company/candidature/${id}/disqualify`;
        return { url, method: 'PATCH', body };
      },
      invalidatesTags: [
        'candidatures',
        'candidatureInternProfile',
        'candidatureOffers',
        'candidatureInterviews',
        'candidatureActions',
      ],
    }),
    getCompanyMandatoryDetails: builder.query<CompanyInfo, number>({
      query: (candidatureId) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/company`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['companyMandatoryDetails'],
    }),
    submitCompanyMandatoryDetails: builder.mutation({
      query: ({ candidatureId, body }) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/company`;
        return { url, method: 'PATCH', body };
      },
      invalidatesTags: ['companyMandatoryDetails'],
    }),

    createOrUpdateOffer: builder.mutation({
      query: (body) => {
        const url = createOrUpdateOfferUrl;
        const method = body?.isEdit ? 'PATCH' : 'POST';
        delete body['isEdit'];
        return { url, method, body };
      },
      invalidatesTags: [
        'candidatureInterviews',
        'candidatureOffers',
        'candidatureInternProfile',
        'candidatures',
        'candidatureActions',
      ],
    }),

    getAppBatchDetails: builder.query({
      query: (offerId) => {
        const url = `${getAppBatchDetailsUrl}/${offerId}`;
        return { url, method: 'GET', body: null };
      },
    }),

    updateOfferStepTwo: builder.mutation({
      query: (body) => {
        const url = updateOfferStepTwoUrl;
        return { url, method: 'POST', body };
      },
      invalidatesTags: [
        'candidatureInterviews',
        'candidatureOffers',
        'candidatureInternProfile',
        'candidatures',
        'candidatureActions',
      ],
    }),

    markInternNotInterested: builder.mutation<CommonResponseType, { internId: number }>({
      query: ({ internId }) => ({
        url: `${notInterestedUrl}`,
        method: 'POST',
        body: {
          intern_id: internId,
        },
      }),
    }),

    getReasonList: builder.query<GetReasonsResponseType, null>({
      query: () => ({
        url: `${notInterestedReasonsUrl}`,
        method: 'GET',
        body: null,
      }),
    }),

    updateNotInterestedReasons: builder.mutation<CommonResponseType, UpdateReasonParamsType>({
      query: ({ internId, notInterestedReasons }) => ({
        url: `${notInterestedUrl}/${internId}`,
        method: 'PATCH',
        body: {
          not_interested_reasons: notInterestedReasons,
        },
      }),
    }),

    undoNotInterested: builder.mutation<CommonResponseType, { internId: number }>({
      query: ({ internId }) => ({
        url: `${notInterestedUrl}/${internId}`,
        method: 'DELETE',
        body: null,
      }),
    }),

    checkNotInterested: builder.query<CheckNotInterestedInternResponseType, { internId: number }>({
      query: ({ internId }) => {
        if (!internId) {
          return null;
        }
        return {
          url: `${notInterestedUrl}/${internId}`,
          method: 'GET',
          body: null,
        };
      },
    }),

    submitOfferDetails: builder.mutation({
      query: (body) => {
        const url = submitOfferDetailsUrl;
        return { url, method: 'POST', body };
      },
      invalidatesTags: [
        'candidatureOffers',
        'candidatureInterviews',
        'candidatureInternProfile',
        'candidatures',
        'candidatureActions',
      ],
    }),

    scheduleInterview: builder.mutation({
      query: (request) => ({ url: scheduleInterviewUrl, method: 'POST', body: request }),
      invalidatesTags: ['candidatures'],
    }),

    getCandidatureActionsStatus: builder.query({
      query: (candidatureId) => {
        const url = `${candidatureByIdUrl}/${candidatureId}/information`;
        return { url, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
      providesTags: ['candidatureActions'],
    }),
  }),
});

export const {
  useGetCandidaturesQuery,
  useGetInternshipPlansQuery,
  useGetInterviewDetailsQuery,
  useSetInterviewTimeSlotsMutation,
  useRescheduleInterviewMutation,
  useGetCandidatureInternProfileQuery,
  useGetCandidatureInterviewsQuery,
  useGetCandidatureOffersQuery,
  useWithdrawInterviewRequestMutation,
  useEditInterviewRequestMutation,
  useGetFeedbackQuestionsAndOptionsQuery,
  useSubmitInterviewFeedbackMutation,
  useMarkCandidateAsHiredMutation,
  useDisqaulifyCandidateMutation,
  useGetCompanyMandatoryDetailsQuery,
  useSubmitCompanyMandatoryDetailsMutation,
  useCreateOrUpdateOfferMutation,
  useGetAppBatchDetailsQuery,
  useUpdateNotInterestedReasonsMutation,
  useUndoNotInterestedMutation,
  useCheckNotInterestedQuery,
  useLazyCheckNotInterestedQuery,
  useGetReasonListQuery,
  useMarkInternNotInterestedMutation,
  useSubmitOfferDetailsMutation,
  useUpdateOfferStepTwoMutation,
  useScheduleInterviewMutation,
  useGetCandidatureActionsStatusQuery,
} = CandidatureApi;
export default CandidatureApi.reducer;
