import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const uploadFileURL = '/common-services/upload-file';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};
export const uploadFile = createAsyncThunk('uploadFile', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(uploadFileURL, request)
    .then((response) => response?.data?.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const UploadFileSlice = createSlice({
  name: 'FileUpload',
  initialState,
  reducers: {
    resetUploadStatus: (state) => {
      state.status = 'idle';
      state.response = null;
      state.isLoading = false;
      state.error= null
    },

  },
  extraReducers(builder) {
    builder
      /* generate file upload state handling */
      .addCase(uploadFile.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.isLoading = false;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getFileUploadState = (state) => state.FileUpload;
export default UploadFileSlice.reducer;
export const { resetUploadStatus } = UploadFileSlice.actions;

