import { createSlice } from '@reduxjs/toolkit';

// toast consists of fields like id,iconType,color,open,title,text
const initialState = {
  messageInfo: {
    toasts: [],
  },
  showLoading: false,
};

const filterdToasts = (removedToastId) =>
  initialState.messageInfo.toasts?.filter((toast) => toast?.id !== removedToastId);

const NotificationSlice = createSlice({
  name: 'TOAST',
  initialState,
  reducers: {
    showSuccessMessage: {
      reducer(state, action) {
        state.messageInfo.toasts = state?.messageInfo?.toasts
          ? [
              ...state.messageInfo.toasts,
              {
                id: `success-message-${Math.random()}`,
                iconType: 'checkInCircleFilled',
                color: 'success',
                open: true,
                ...action.payload,
              },
            ]
          : [action.payload];
      },
    },
    showWarningMessage: {
      reducer(state, action) {
        state.messageInfo.toasts = state?.messageInfo?.toasts
          ? [
              ...state.messageInfo.toasts,
              {
                id: `warning-message-${Math.random()}`,
                iconType: 'alert',
                color: 'warning',
                open: true,
                ...action.payload,
              },
            ]
          : [action.payload];
      },
    },
    showErrorMessage: {
      reducer(state, action) {
        state.messageInfo.toasts = state?.messageInfo?.toasts
          ? [
              ...state.messageInfo.toasts,
              {
                id: `success-message-${Math.random()}`,
                iconType: 'alert',
                color: 'danger',
                open: true,
                ...action.payload,
              },
            ]
          : [action.payload];
      },
    },
    dismissToastMessage: {
      reducer(state, action) {
        state.messageInfo.toasts = filterdToasts(action.payload.id);
      },
    },
    clearAllDismissToastMessage: {
      reducer(state) {
        state.messageInfo.toasts = []; // Clear all toasts by setting the array to an empty array.
      },
    },
  },
});

export const {
  showSuccessMessage,
  showWarningMessage,
  showErrorMessage,
  dismissToastMessage,
  clearAllDismissToastMessage,
} = NotificationSlice.actions;
export const getNotificationState = (state) => state.TOAST;

export default NotificationSlice.reducer;
