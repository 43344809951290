import emailCheckReducer from '../Slices/Common/EmailCheck';
import tokenReducer from '../Slices/Auth/tokenSlice';
import generateTokenReducer from '../Slices/Auth/GenerateToken';
import userAuthenticateReducer from '../Slices/Auth/ValidateSignInOTP';
import generateOTPReducer from '../Slices/Auth/GenerateOTP';
import generateOTPWithHashReducer from '../Slices/Auth/GenerateOTPHash';
import verifyOTPWithHashReducer from '../Slices/Auth/VerifyOTPWithHash';
import checkTokenHashReducer from '../Slices/Auth/CheckToken';
import loadLocalesReducer from '../Slices/Common/LoadLocales';
import uploadFileReducer from '../Slices/Common/UploadFileS3';
import toastReducer from '../Slices/Common/Notification';
import createUserReducer from '../Slices/Auth/CreateUser';
import feedbackQuestionsAndOptionsReducer from '../Slices/Common/Feedback/GetFeedbackQuestionsAndOptions';
import saveInvitedUserDetailReducer from '../Slices/Auth/SaveInvitedUserDetails';
import invokeGlobalPopupReducer from '../Slices/Common/InvokeGlobalPopup';
import snackbarReducer from '../Slices/Common/SnackbarSlice';
import matTextAreaReducer from '../Slices/Common/MatTextAreaSlice';
import { GetFeatureFlagApi } from '../Slices/Common/GetFeatureFlagSlice';
import { CompanyServices } from '../Slices/Common/CompanyServices';
import saveTokenReducer from '../Slices/Auth/SaveToken';
import { DeleteFileApi } from '../Slices/Common/DeleteS3FileRtk';

export const commonReducers = {
  CheckEmail: emailCheckReducer,
  Token: tokenReducer,
  GenerateToken: generateTokenReducer,
  UserAuthenticate: userAuthenticateReducer,
  GenerateOTP: generateOTPReducer,
  GenerateOTPWithHash: generateOTPWithHashReducer,
  VerifyOTPWithHash: verifyOTPWithHashReducer,
  LoadLocales: loadLocalesReducer,
  FileUpload: uploadFileReducer,
  TOAST: toastReducer,
  CreateUser: createUserReducer,
  GetFeedbackQuestionsAndOptions: feedbackQuestionsAndOptionsReducer,
  SaveInvitedUserDetails: saveInvitedUserDetailReducer,
  InvokeGlobalPopup: invokeGlobalPopupReducer,
  CheckToken: checkTokenHashReducer,
  Snackbar: snackbarReducer,
  MatTextArea: matTextAreaReducer,
  [GetFeatureFlagApi.reducerPath]: GetFeatureFlagApi.reducer,
  [CompanyServices.reducerPath]: CompanyServices.reducer,
  savedToken: saveTokenReducer,
  [DeleteFileApi.reducerPath]: DeleteFileApi.reducer,
};
